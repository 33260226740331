import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react';

import { Container, Content, ErrorMessage } from './styles';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  error: string | undefined;
}

const TextAreaBase: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
  { placeholder, type, error, ...rest },
  ref
) => {
  return (
    <Container>
      <Content>
        <textarea
          {...rest}
          placeholder={placeholder}
          cols={5}
          rows={10}
          ref={ref}
        />
      </Content>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export const TextArea = forwardRef(TextAreaBase);
