import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    --primary: ${process.env.REACT_APP_PRIMARY_COLOR};
    --secondary: ${process.env.REACT_APP_SECONDARY_COLOR};
    --background: ${process.env.REACT_APP_BACKGROUND_COLOR};
    --white: #fff;
    --error: #f00;

    @media(max-width: 1080px) {
      font-size: 93.75%;
    }

    @media(max-width: 720px) {
      font-size: 87.5%;
    }
  }

  html, body {
    height: 100%;
  }
  
  body {
    background-color: ${process.env.REACT_APP_BACKGROUND_COLOR};
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  textarea, input {
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }

  button {
    cursor: pointer;
  }
`

export default GlobalStyles