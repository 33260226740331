import { Form } from '../components/form';
import bovassist from '../assets/bovassist_logo.png';
import bovbeef from '../assets/bovbeef_logo.png';
import bovcontrol from '../assets/bovcontrol_logo.png';
import bovcrypto from '../assets/bovcrypto_logo.png';
import bovdairy from '../assets/bovdairy_logo.png';
import bovimpact from '../assets/bovimpact_logo.png';
import bovinsights from '../assets/bovinsights_logo.png';
import bovmilk from '../assets/bovmilk_logo.png';
import sabia from '../assets/sabia_logo.png';
import leiteria from '../assets/leiteria_logo.png';
import sdl from '../assets/sdl_logo.png';
import piracanjuba from '../assets/piracanjuba_logo.png';
import bovpilot from '../assets/bovpilot_logo.png';
import bovplatform from '../assets/bovplatform_logo.png';
import vivo from '../assets/vivo_logo.png';
import roda from '../assets/roda_logo.png';

import { Container, Content, Title, SubTitle } from './styles';

type LogosGPLI =
  | 'bovassist'
  | 'bovbeef'
  | 'bovcontrol'
  | 'bovcrypto'
  | 'bovdairy'
  | 'bovimpact'
  | 'bovinsights'
  | 'bovpilot'
  | 'bovmilk'
  | 'sabia'
  | 'leiteria'
  | 'sdl'
  | 'piracanjuba'
  | 'bovplatform'
  | 'vivo'
  | 'roda';

function App() {
  const logo = (process.env.REACT_APP_LOGO! as LogosGPLI) || 'bovcontrol';

  const allLogos = {
    bovassist,
    bovbeef,
    bovcontrol,
    bovcrypto,
    bovdairy,
    bovimpact,
    bovinsights,
    bovmilk,
    sabia,
    leiteria,
    sdl,
    piracanjuba,
    bovpilot,
    bovplatform,
    vivo,
    roda
  };

  return (
    <Container>
      <Content>
        <img src={allLogos[logo]} alt={process.env.REACT_APP_LOGO} />

        <Title>{process.env.REACT_APP_TITLE}</Title>
        <SubTitle>{process.env.REACT_APP_SUBTITLE}</SubTitle>
        <Form />
      </Content>
    </Container>
  );
}

export default App;
