import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FiCheckCircle } from 'react-icons/fi';
import { FaUpload } from 'react-icons/fa';

import { Input } from '../input';
import { TextArea } from '../text-area';
import { InputsForm } from '../../interfaces';
import { api } from '../../service/api';

import {
  Container,
  Content,
  ContentForm,
  Button,
  ErrorMessage,
  ContentSelect,
  UploadImage,
  DropImage,
} from './styles';
import { useState } from 'react';

const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | string>('');

  const fields = process.env.REACT_APP_ADDITIONAL_FIELDS?.split(',');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>();

  const submitForm = async (data: InputsForm) => {
    const form = new FormData();

    const {
      name,
      email,
      description,
      subject: { value },
      title,
    } = data;

    try {
      setIsLoading(true);
      let message = '';

      if (process.env.REACT_APP_ADDITIONAL_FIELDS) {
        fields?.map((field) => {
          return (message += `[${field}]: ${data[field]}\\n`);
        });
      }

      message += description;

      if (file) form.append('_filename', file);

      form.append('name', name);
      form.append('email', email);
      form.append('message', message);
      form.append('category', String(value));
      form.append('title', String(title.value));
      form.append('entity', String(process.env.REACT_APP_ENTITY));

      await api.post('', form);
      reset();
      setValue('subject', '');
      setValue('title', '');
      setFile('');
      alert('Mensagem enviada com sucesso!');
    } catch (error) {
      alert(
        `Não foi possível enviar seus dados. Tente novamente em algumas horas. Caso o problema persista, não hesite em nos contatar: ${process.env.REACT_APP_EMAIL_SUPPORT || 'suporte@bovcontrol.com'}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getOptions = () => {
    const allOptions = (process.env.REACT_APP_OPTIONS || '').split(',');

    const optionsFormatted = allOptions.map((option) => ({
      value: option,
      label: option.charAt(0).toUpperCase() + option.slice(1),
    }));

    return optionsFormatted;
  };

  const getTitles = () => {
    const allTitles = (process.env.REACT_APP_TITLES || '').split(',');

    const titlesFormatted = allTitles.map((title) => ({
      value: title,
      label: title.charAt(0).toUpperCase() + title.slice(1),
    }));

    return titlesFormatted;
  };

  const submitFile = (files: FileList | null) => {
    const file = files ? files[0] : null;

    if (!file) return;
    const maxSizeFile = 2 * 1024 * 1024;

    if (file.name.match(/[^0-9a-zA-Z._]+/)) {
      alert('Nome do arquivo deve conter apenas letras ou números!');
      return;
    }

    if (file.size > maxSizeFile) return alert('Arquivo maior que 2mb');

    setFile(file);
  };

  return (
    <Container>
      <Content>
        <ContentForm onSubmit={handleSubmit(submitForm)}>
          <Input
            {...register('name', { required: 'Nome Obrigatório' })}
            error={errors.name?.message}
            placeholder={'Seu nome'}
          />
          <Input
            {...register('email', { required: 'E-mail Obrigatório' })}
            error={errors.email?.message}
            placeholder="Seu E-mail"
          />
          {process.env.REACT_APP_ADDITIONAL_FIELDS &&
            fields?.map((field, index) => {
              return (
                <Input
                  key={index}
                  {...register(field, { required: `${field} Obrigatório` })}
                  error={errors[field]?.message}
                  placeholder={field}
                />
              );
            })}
          <Controller
            name="subject"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ContentSelect>
                <Select
                  placeholder="Selecione uma Categoria"
                  options={getOptions()}
                  value={value}
                  onChange={onChange}
                />
                {errors.subject && (
                  <ErrorMessage>Categoria Obrigatório</ErrorMessage>
                )}
              </ContentSelect>
            )}
          />
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ContentSelect>
                <Select
                  placeholder="Selecione um Título"
                  options={getTitles()}
                  value={value}
                  onChange={onChange}
                />
                {errors.title && (
                  <ErrorMessage>Título Obrigatório</ErrorMessage>
                )}
              </ContentSelect>
            )}
          />
          <TextArea
            {...register('description')}
            error={errors.description?.message}
            placeholder="Coloque uma Descrição"
          />
          <UploadImage>
            <DropImage htmlFor="upload-file">
              {!file ? (
                <>
                  <FaUpload />
                  Faça upload do seu documento
                </>
              ) : (
                <>
                  <FiCheckCircle color="var(--primary)" />
                  Arquivo carregado com sucesso
                </>
              )}
              <input
                onChange={(e) => {
                  submitFile(e.target.files);
                  e.target.value = '';
                }}
                accept="*"
                id="upload-file"
                type="file"
              />
            </DropImage>
          </UploadImage>
          <Button disabled={isLoading} type="submit">
            {isLoading ? 'Enviando...' : 'Enviar'}
          </Button>
        </ContentForm>
      </Content>
    </Container>
  );
};

export { Form };
