import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from 'react';

import { Container, Content, ErrorMessage } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error: string | undefined;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { placeholder, type, error, ...rest },
  ref
) => {
  return (
    <Container>
      <Content>
        <input {...rest} placeholder={placeholder} type={type} ref={ref} />
      </Content>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export const Input = forwardRef(InputBase);
