import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Content = styled.div`
  max-width: 580px;
  box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.1);
  background-color: var(--secondary);
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  img {
    width: 120px;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    height: auto;
    padding: 5rem 5rem 4rem;
    margin: 0 auto;

    img {
      width: 160px;
    }
  }
`

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`

export const SubTitle = styled.h1`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`