import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div``

export const ContentForm = styled.form`
  width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  >div {
    width: 330px;

    & + div {
      margin-top: 1rem;
    }
  }

  button {
    margin-top: 2rem;
    width: 330px;
    transition: opacity 0.6s;

    &:hover {
      background-color: var(--primary);
      opacity: 0.9;
    }
  }

  @media (min-width: 768px) {
    >div {
      width: 360px;
    }

    button {
      width: 360px;
    }
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  height: 40px;
  width: 100%;
  border: none;
  color: var(--white);
  font-size: 1rem;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`

export const ContentSelect = styled.div`
  >div {
    >div {
      border: 1px solid var(--primary);
      border-radius: 0px;
      font-size: 0.875rem;
      box-shadow: none;

      &:hover {
        border: 1px solid var(--primary);
      }

      .css-tlfecz-indicatorContainer {
        color: var(--primary);
      }

      .css-1okebmr-indicatorSeparator {
        background-color: var(--primary);
      }
    }
  }
`

export const ErrorMessage = styled.p`
  margin-top: 0.5rem;
  color: var(--error);
  font-size: 0.75rem;
  
  @media (min-width: 640px) {
    font-size: 0.875rem;
  }
`

export const UploadImage = styled.div`
  background-color: var(--white);
  border: 1px solid var(--primary);
  height: 8rem;
  width: 100%;
  color: hsl(0, 0%, 50%);

  input {
    display: none;
  }
  
  svg {
    width: 30px;
    height: 30px;
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    width: 250px;
  }
`

export const DropImage = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 0.875rem;
`