import styled from "styled-components"


export const Container = styled.div``

export const Content = styled.div`
  background-color: var(--white);
  border: 1px solid var(--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  textarea {
    padding: 0.75rem 0.5rem;
    flex: 1;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }
`

export const ErrorMessage = styled.p`
  margin-top: 0.5rem;
  color: var(--error);
  font-size: 0.75rem;
  
  @media (min-width: 640px) {
    font-size: 0.875rem;
  }
`